<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">笔迹专家秀绝技 上电视准确“破案”</div>
      <p class="time">2018.01.14</p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/10-1.png')">
      </div>

      <p class="space_content">
        厦门晚报报道：
        （记者 王东城）2018 年.1月.13日在湖南卫视《快乐大本营》上，厦门笔迹学会终身荣誉会长金一贵和学会常务副会长兼秘书长许桂月受邀登台亮相，以字“破案”，找出了案件“主谋”。
      </p>
      <p class="space_content">
        “目前国内仅有厦门成立了笔迹学会。”金一贵说。那么笔迹学的神奇之处在哪里？昨天，记者采访了金一贵。
      </p>

      <p class="space_content titleText">节目事先没有任何提示 厦门笔迹专家技惊四座</p>
      <p class="space_content">
        “节目组设置了一个剧情:‘主谋’指使同伙‘作案’，两人之间有书信往来。最后，节目组为了找出‘主谋’，请所有‘嫌疑人’按照书信内容现场书写，让我辨认。”金一贵说，这次节目录制事先没有任何提示，都是他和许桂月现场分析得出结论，而且按照湖南卫视的要求，将两人分析的过程大声表达出来，节目组还打出字幕。
      </p>
      <p class="space_content">
        这期节目上，王凯、马天宇、王大陆、叶祖新等实力演员施展演技，让“案情”更加扑朔迷离。
      </p>
      <p class="space_content">
        在节目组提供的书信中，金一贵注意到，其中的“麦”和“货”两个字，捺的写法一致，此外 “取” “日” “上”三个字的竖画部分都有笔直的特点。加上字体的紧凑度、写法习惯等元素，金一贵和许桂月联手锁定了嫌疑人。虽然难度很大，但是最终结果显示，他们准确无误地找出了“主谋”马天宇，让在场嘉宾目瞪口呆。
      </p>

      <p class="space_content titleText">揭秘笔迹分析有科学性 研究也需要大数据
      </p>
      <p class="space_content">
        “书写过程中不同的握笔、用笔方法，坐姿，放纸的位置等，会使书写者形成不同的行为，时间一长，行为模式将向心理模式转移。”金一贵说，笔迹学是一门科学，而不是单纯的主观臆测。
      </p>
      <p class="space_content">
        金一贵在节目上说，按照国际统计惯例，每一个重要的笔迹特征至少需要500个例子来佐证，厦门则做到5000个例子，就像大数据一样，更加准确。
      </p>
      <p class="space_content">
        金一贵告诉记者，他从小就喜欢将人的动作看成一个字，同时也喜欢将字看成人的形态。“我小时候，大约小学五六年级时，将家里的行书字帖上的行书字全部画成人的形态。现在想来，当时已经有笔迹分析的萌芽。”
      </p>
      <p class="space_content">
        金一贵的笔迹分析有三大要素:压力、节奏和字体大小。压力，反映着书写者对外界的态度。通常来讲，写字力大且用力均衡的人，其独立处理问题的能力要高于一般人。节奏则反映了书写者本人的工作生活节奏。字体的大小与书写者对外界的拓展力度成正比。
      </p>
      <p class="space_content">
        金一贵说，笔迹学的应用范围很广，从刑侦、文书司法鉴定、考古再到教育等领域，都具备较强参考借鉴价值，他本人有不少成功案例。
      </p>

      <p class="space_content titleText">观点：书写风格改变了 性格也会跟着变
      </p>
      <p class="space_content">
        金一贵曾从事美术和书法教学研究。他指导学生练字的时候，注意到有的家长、老师不加选择让孩子练字，有的学生虽然练字进步很快，但是内向的学生更加内向了，外向的学生更张扬了。
      </p>
      <p class="space_content">
        金一贵尝试反过来补救，他让内向的学生先练习颜真卿的字体然后练习行书，因为颜真卿的字大气包容。他又让外向的练习欧阳询的字体，因为欧阳询的字很规矩、严谨。最后经过一番努力，字变人也变，练字的学生个性也跟着受影响。
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/8'"><p class="ellipsis1">
            历思受邀为党员律师讲解司法鉴定实务专题学习活动</p></router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/11'"><p class="ellipsis1">科学仪器为您揭开古玩艺术品的真伪</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
